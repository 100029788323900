<template>
  <form
    action
    data-vv-scope="customer-info-form"
    @submit.prevent="validateForm('customer-info-form')"
  >
    <v-row>
      <v-col cols="12">
        <h2 v-if="customer.extra_customers.length">{{ $tc("customer") }} 1</h2>
        <customers-form
          ref="customer_form"
          :title="$t('new', { name: $tc('customer') })"
          :customer="customer"
          :buttons="false"
          :customer_settings="customerSettings"
        >
          <v-col
            class="py-0"
            cols="12"
            sm="6"
            md="3"
            v-if="customerSettings.meet.view"
          >
            <label style="height: 25px !important">{{
              $t("appointments.how_meet")
            }}</label>
            <v-text-field
              dense
              outlined
              v-model="customer.how_meet"
            ></v-text-field>
          </v-col>

          <v-col
            class="py-0"
            cols="12"
            md="6"
            v-if="$route.name === 'customer_inscription_studio_asesoramiento'"
          >
            <label>{{ $t("appointments.description") }}</label>
            <v-textarea
              outlined
              v-model="customer.description"
              auto-grow
              rows="1"
              hide-details
              style="border-radius: 10px"
            ></v-textarea>
          </v-col>
        </customers-form>
        <slot></slot>
      </v-col>
      <v-col v-for="(c, i) in customer.extra_customers" :key="i" cols="12">
        <v-divider />
        <h2>{{ $tc("customer") }} {{ i + 2 }}</h2>
        <customers-form
          :ref="`customer_form_extra`"
          :title="$t('new', { name: $tc('customer') })"
          :customer="c"
          :buttons="false"
          :customer_settings="customerSettings"
        >
          <v-col
            class="py-0"
            cols="12"
            sm="6"
            md="3"
            v-if="customerSettings.meet.view"
          >
            <label style="height: 25px !important">{{
              $t("appointments.how_meet")
            }}</label>
            <v-text-field dense outlined v-model="c.how_meet"></v-text-field>
          </v-col>
        </customers-form>
      </v-col>
    </v-row>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        @click="$emit('back')"
        outlined
        style="height: 30px"
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px"
        elevation="0"
        class="ml-2"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: "" }) }}
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CustomerInfo",
  data() {
    return {
      selected: false,
    };
  },
  props: ["appointment", "skip_validate"],
  mounted() {
    /*
    this.studio();
    this.link();
    this.url();
    */
  },
  methods: {
    async validateForm(scope) {
      //console.log("validateForm");
      if (this.skip_validate) {
        this.$emit("validated");
        return;
      }
      this.errors.clear();

      let customer_val = await this.$refs.customer_form.validateForm(
        "customer-form"
      );

      let customer_extra_val = true;
      if (this.customer.extra_customers.length)
        for (let component of this.$refs[`customer_form_extra`]) {
          let r = await component.validateForm("customer-form");
          if (r === false) customer_extra_val = false;
        }

      let result = await this.$validator.validateAll(scope);
      //console.log("validateForm", result, customer_val);
      if (result && customer_val && customer_extra_val) {
        this.$emit("validated", this.studio);
      }
    },
  },
  components: {
    CustomersForm: () => import("@/components/customer/Form"),
  },
  computed: {
    ...mapState("inscriptions", ["customerState"], "studio"),

    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },

    customerSettings() {
      if (this.customer.studio) {
        let s = this.customer.studio.user.setting.find(
          (x) => x.key === "requested_client_information"
        );
        if (s) {
          return JSON.parse(s.value);
        }
      }
      return this.$store.getters["auth/getSetting"](
        "requested_client_information"
      );
    },
  },
};
</script>


<style lang="sass" scoped>
label
  display: block
  height: 25px !important
</style>